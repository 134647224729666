import React from "react";
import { Container, Title, Text, DotIcon, ListBox } from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const FirstVisit = () => {
  return (
    <>
      <Breadcrumb pageTitle="Your First Orthodontic Visit">
        <BreadcrumbItem
          Link="/patient-information/first-visit"
          Text="Your First Orthodontic Visit"
        />
      </Breadcrumb>
      <BannerImages imagesrc="consult" />

      <PageContainer>
        <Container>
          <Text>
            At Gippsland Orthodontics, our goal is to give you a healthy,
            straight, beautiful smile that you&#39;ve always wanted. We
            understand that choosing to receive orthodontic care is a big
            decision. Our practice will work with you every step of the way to
            make sure that your orthodontic care is as rewarding as possible.
            Gippsland Orthodontics utilizes the latest technology, offering a
            variety of treatment options to give you the customized, individual
            care that you deserve.
          </Text>
          <Title>Every smile starts with an initial consultation</Title>
          <Text>
            Your initial consultation at Gippsland Orthodontics will give you
            the opportunity to meet our team, learn more about orthodontics and
            find out which treatment options will best meet your needs.
          </Text>
          <Text>
            During your initial consultation we want to get to know each other
            and will involve:
          </Text>
          <ListBox>
            <DotIcon />
            <p>
              Being greeted by our friendly reception and administrative team
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>Review your medical and dental history forms</p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              Provide an examination in which we look at the overall face,
              mouth, teeth, gums and x-rays. This examination will determine if
              you require treatment.
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              If treatment is required, we will often discuss treatment options
              available to you and help create a customized treatment plan
            </p>
          </ListBox>
          <ListBox>
            <DotIcon />
            <p>
              Discuss all financial information, private health insurance, and
              payment plans
            </p>
          </ListBox>
          <br />
          <Text>
            The treatment plan may require additional digital records of your
            teeth being taken (can be in the form of 3D models, x-rays or
            digital photographs) as they will be required for a personalised
            treatment plan. Please note we may request x-rays to be taken prior
            to the initial consultation.
          </Text>
          <Text>
            At this initial appointment, it is crucial that you ask questions
            and list any concerns that you may have. Do not hesitate in asking
            us anything, as we want to ensure that all your queries are taken
            care of. Our team will be glad to answer any questions you may have,
            and we will provide all of the information that you need to
            determine which treatment is appropriate for you.
          </Text>
          <Title>What happens after the initial consultation?</Title>
          <Text>
            If you have decided that orthodontic treatment is right for you,
            you&#39;re one step closer to achieving the smile you&#39;ve always
            wanted! After your consultation, we will schedule your first
            appointment and Dr Adam will place your new orthodontic appliance.
            This appointment generally takes about an hour, and during this time
            our team will be available to make sure that you are as comfortable
            as possible.
          </Text>
          <Title>
            <i>
              If your child requires treatment, but still needs time to develop
              and grow, treatment may be delayed till an appropriate time. If
              this is the case, you will be given a return date to begin
              treatment at a later stage.
            </i>
          </Title>
          <Title>Scheduling Appointments</Title>
          <Text>
            Our practice will work with you to ensure that your appointments are
            as convenient as possible. It is important to understand that
            visiting the orthodontist about every four to ten weeks is an
            essential part of your orthodontic treatment. When scheduling
            appointments, please let us know what days and times work best for
            you.
          </Text>
          <Text>
            We understand that “life happens” at times! Getting stuck behind
            that milk tanker, running late while picking the kids up at school
            and leaving the appliance at home happens! If you&#39;re running
            late or need to reschedule, please let us know as soon as possible.
            We will do everything we can to accommodate you with reasonable
            notice.
          </Text>
          <Text>
            When you&#39;re ready to schedule your first appointment, contact
            our practice and our friendly scheduling coordinator will assist
            you. We&#39;re looking forward to working with you during this
            journey.
          </Text>
          <Title>Visiting The Dentist During Orthodontic Treatment</Title>
          <Text>
            It is extremely important that you continue to visit your family
            dentist once every six months, even during your orthodontic
            treatment. Dentists are awesome at completing routine dental
            check-ups and professional teeth cleans. If extra dental care is
            needed, we will be happy to coordinate with your family dentist to
            make sure that you are receiving the best care possible.
          </Text>
          <Text>
            In some cases, we may have to work with a dental specialist to treat
            an underlying disease condition. This may include an Ear Nose and
            Throat specialist, dental specialist (for example, a periodontist or
            a prosthodontist) or an allied health specialist (i.e., speech
            pathologist) to work together to achieve the best outcome for your
            treatment.
          </Text>
          <Title>
            Our mission is to make you feel comfortable and give you a smile to
            be happy about!
          </Title>

          {/* <Wrapper>
            <ContentsContainer>
              <Text>
                Adam Wallace is a specialist orthodontist who has had more than
                17 years of experience in the dental profession. He was born and
                raised in Arawata, South Gippsland. His mum owned the Quilter’s
                Barn in Loch and his dad worked with Graham Rowe at Vin Rowe
                Farm Machinery of which he has had many fond memories as a kid!
              </Text>
              <Text>
                Adam attended Chairo Christian School (Leongatha campus) and
                went on to complete both his Dentistry and Orthodontic
                specialist training at the University of Melbourne. He has
                continued to lecture, demonstrate and examine at the University
                since graduating in 2005. Additionally, he examines overseas
                trained dentists at the Australian Dental Council. As much as
                Adam loves teaching, the majority of his career has been spent
                at a practice in Heathmont, Melbourne.
              </Text>
              <Text>
                Adam’s undergraduate research was quite apt coming from a dairy
                farm. He studied the casein proteins in milk and the protective
                effect on teeth to help reduce plaque formation. Milk is
                important for teeth and funnily enough, it seems to be an
                integral part of the family with his cousin’s husband being one
                half of the team running the awesome local business, Gippsland
                Jersey! Adam went on to graduate the Bachelor of Dental Science
                degree as valedictorian with honours and received a total of ten
                scholarships, prizes, awards and special achievements throughout
                his undergraduate degree.
              </Text>
              <Text>
                Following graduation, he went onto his Masters, researching the
                effects of bisphosphonate use on treatment of benign bone
                disease and impaired dental healing. His passion for academics
                pursued and he has held several academic roles including the
                Clinical Practice and Specialist Areas in Dentistry
                (Orthodontics) Convenor for the Bachelor of Oral Health program.
                Recently, his doctoral thesis was an important clinical study on
                the treatment outcome between Invisalign and traditional braces
                which intends to be published soon.
              </Text>
              <Text>
                Adam is passionate about orthodontics and thoroughly enjoys
                creating beautiful smiles for his patients. He likes to provide
                a personalised approach to his treatment to ensure that patients
                feel welcome and comfortable. Further to this, specialist
                orthodontic training and certification with the Australasian
                Board of Orthodontists has allowed him to appreciate the finer
                attention to detail in treatment.
              </Text>
              <Text>
                Outside Adam’s passion for his work and devotion to his
                patients, he is kept busy with a farm in the Strzelecki ranges
                where he has been living for the last 10 years. He currently has
                angus cattle, sheep, alpacas and two border collie x kelpie
                dogs. Family is also really important, and he looks forward to
                being able to spend more time with his family in the local areas
                of Drouin, Jindivick, Inverloch and Sale.
              </Text>
              <Text>
                Adam aims to provide specialist care for all his patients with a
                kind, gentle nature and looks forward to seeing several
                generations of patients in the future.
              </Text>
              <Text>
                Dr Adam provides treatment for all ages with affordable
                interest-free payment plans at Gippsland Orthodontics.
              </Text>
              <ListBox>
                <DotIcon />
                <p>Traditional braces</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Clear braces</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Invisalign and clear aligner treatment</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Early intervention orthodontic treatment</p>
              </ListBox>
              <ListBox>
                <DotIcon />
                <p>Teen and adult treatment</p>
              </ListBox>
            </ContentsContainer>
            <ImageContainer>
              <AboutImage />
            </ImageContainer>
          </Wrapper> */}
        </Container>
      </PageContainer>
    </>
  );
};

export default FirstVisit;
