import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { colors } from "../../styles/variables";
import FirstVisit from "../../components/patient-information/first-visit";

const FirstVisitPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="Your First Orthodontic Visit" />
    <FirstVisit />
  </Layout>
);

export default FirstVisitPage;
